import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid} from '@material-ui/core';
import {withStyles} from '@material-ui/styles';
import {get} from 'lodash';
import * as React from 'react';
import {defaultLanguageLocalStorageKey} from '../constants';
import AutocompleteInput from './AutocompleteInput';

const styles = {
  cancelButtonDiv: {
    position: 'absolute',
    paddingRight: '4%',
  },
  okButtonDiv: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
  okButton: {
    width: '35%',
  },
  dialogTitle: {
    overflow: 'hidden',
    whiteSpace: 'break-spaces',
  },
};

const LanguagesDialog = ({handleClose, handleClick, processingFiles, classes}) => {
  const currentFilename = get(processingFiles, [0, 'name'], null);
  const [value, changeValue] = React.useState('auto');

  React.useEffect(() => {
    const defaultLanguage = localStorage.getItem(defaultLanguageLocalStorageKey);

    if (defaultLanguage) {
      changeValue(defaultLanguage);
      localStorage.removeItem(defaultLanguageLocalStorageKey);
    }
  }, []);

  return (
      <Dialog
          open={!!processingFiles.length}
      >
        <DialogTitle className={classes.dialogTitle}>
          Choose language for document: "{currentFilename}"
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <AutocompleteInput value={value} changeValue={changeValue} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <div className={classes.okButtonDiv}>
            <Button
                color='primary'
                onClick={() => {
                  handleClick(value, currentFilename);
                }}
                className={classes.okButton}
                size='large'
                variant='contained'
            >
              Ok
            </Button>
          </div>
          <div className={classes.cancelButtonDiv}>
            <Button onClick={() => handleClose(currentFilename)}>Cancel</Button>
          </div>
        </DialogActions>
      </Dialog>
  );
};

export default withStyles(styles)(LanguagesDialog);