import React, {useMemo} from 'react';
import ScrollMenu from 'react-horizontal-scrolling-menu';
import './HorizontalScroll.css';
import {languagesDict, languageUrlPrefix} from "../../constants";
import * as PropTypes from "prop-types";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

const getUrlLanguage = (langId) => {
    switch (langId) {
        case 'chi':
            return 'chinese';
        case 'auto':
            return 'auto';
        default:
            return languagesDict[langId].toLowerCase();
    }
}

const MenuItem = ({text, selected, langId}) => {
    const path = `${languageUrlPrefix}${getUrlLanguage(langId)}`;

    return (
        <a
            className={`menu-item ${selected ? "active" : ""}`}
            href={path}
        >
            {text}
        </a>
    );
};

export const getMenu = () =>
    Object.keys(languagesDict).map(key => {
        const name = languagesDict[key];

        return <MenuItem text={name} langId={key} key={key}/>;
    });

const Arrow = ({forward, className}) => {
    const Icon = forward ? ArrowForwardIosIcon : ArrowBackIosIcon;
    return <Icon className={className}/>;
};
Arrow.propTypes = {
    text: PropTypes.string,
    className: PropTypes.string
};

export const ArrowLeft = ({className = 'arrow-prev'}) => {
    return (
        <ArrowBackIosIcon className={className}/>
    )
};
export const ArrowRight = ({className = 'arrow-next'}) => {
    return (
        <ArrowForwardIosIcon className={`${className}`}/>
    )
};

const NavBarHorizontal = ({languageId}) => {
    const menuItems = useMemo(() => getMenu(), []);

    return (
        <div className="container">
            <ScrollMenu
                data={menuItems}
                arrowLeft={<ArrowLeft/>}
                arrowRight={<ArrowRight/>}
                alignCenter={false}
                clickWhenDrag={false}
                hideArrows={true}
                hideSingleArrow={true}
                /* if we hardcode scrollToSelected={true}, we will get the following scenario:
                    1) Components are loading, languageId is null
                    2) Scroll to the end get started because languageId is null
                    3) languageId gets not null but scroll in progress yet and new scroll position isn't considered
                */
                scrollToSelected={!!languageId}
                selected={languageId}
                transition={0}
            />
        </div>
    );
}

export default NavBarHorizontal;