import {Button, Tab, Tooltip, Typography} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const componentStyles = {
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    defaultIconStyle: {
        cursor: 'pointer',
    },
    defaultDisabledIconStyle: {
        cursor: 'not-allowed',
    },
    tooltipTitle: {
        fontSize: 14,
        color: '#000000',
    },
};

const StyledTooltip = withStyles({tooltip: {background: '#ffffff'}})(Tooltip);

const TabComponent = React.forwardRef(({children, iconProps, onIconClick, classes, canBeDeleted, ...rest}, _ref) => {
    const {className: iconPropsClassName, ...restIconProps} = iconProps;

    const defaultIconClassName = classes.defaultIconStyle;
    const iconClassName = classNames(defaultIconClassName, iconPropsClassName);

    const tooltipText = 'Remove';
    return (
        <div className={classes.root}>
            <Button
                {...rest}
            >
                {children}
            </Button>
            {canBeDeleted ? <StyledTooltip
                    title={
                        <Typography className={classes.tooltipTitle}>
                            {tooltipText}
                        </Typography>
                    }
                >
                    <CloseIcon
                        {...restIconProps}
                        className={iconClassName}
                        onClick={() => canBeDeleted ? onIconClick && onIconClick() : null}
                    />
                </StyledTooltip>
                : null
            }
        </div>
    );
});

const StyledTabComponent = withStyles(componentStyles)(TabComponent);

const TabWithClose = (props) => {
    return (
        <Tab
            iconProps={{}}
            {...props}
            component={StyledTabComponent}
        />
    );
};

TabWithClose.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
    iconProps: PropTypes.object,
    onIconClick: PropTypes.func,
};

export default TabWithClose;