import PDFObject from 'pdfobject';
import PropTypes from 'prop-types';
import React, {Component} from 'react';

class PdfViewer extends Component {
    embedPdf() {
        const {containerId, rawData} = this.props;
        const pdfForRender = new Blob([rawData], {type: 'application/pdf'});
        const pdfFileURL = URL.createObjectURL(pdfForRender);

        PDFObject.embed(`${pdfFileURL}`, `#${containerId}`);
    }

    componentDidMount() {
        this.embedPdf();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.embedPdf();
    }

    render() {
        const {width, height, containerId} = this.props;

        return (<div style={{width, height}} id={containerId}/>);
    }
}

PdfViewer.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string,
    containerId: PropTypes.string
};

PdfViewer.defaultProps = {
    width: '100%',
    height: '100%',
    containerId: 'pdf-viewer'
};

export default PdfViewer;
