import Grid from '@material-ui/core/Grid';
import React from 'react';

const Info = () => {
    return (
        <Grid container spacing={4} className="App-info" justify='center' alignItems='center'>
            <Grid container item xs={12} spacing={2} justify='center'>
                <Grid item md={4} xs={12} className='App-info-container'>
                    <div className='App-info-icon'>
                        <img src={require('../icons/convert.png')} height={100} width={100} alt='2OCR - Convert input file to a searchable PDF, PDF/A, Text' />
                    </div>
                    <div className='App-info-subtitle'>
                        <h2>Convert Image to a searchable PDF, PDF/A, Text</h2>
                    </div>
                    <div className='App-info-text'>
                        Extract text from a captured photo,
                        scanned image file and use data to copy/paste or process it for any other
                        need. No need to type text manually anymore, image digitising is simple now
                    </div>
                </Grid>
                <Grid item md={4} xs={12} className="App-info-container">
                    <div className="App-info-icon">
                        <img src={require('../icons/formats.png')} height={100} width={100} alt='2OCR - Upload any file format' />
                    </div>
                    <div className="App-info-subtitle">
                        <h2>Upload any file format</h2>
                    </div>
                    <div className="App-info-text">
                        Word, Excel and PDF files have a lot of images inside and this creates certain issues in documents data
                        extraction. With 2ocr tool, you can upload the whole document to recognize all images. Supported file
                        formats: .jpg, .png, .tiff, .bmp, .heic, .webp, .pdf, .doc, .xls
                    </div>
                </Grid>
                <Grid item md={4} xs={12} className="App-info-container">
                    <div className="App-info-icon">
                        <img src={require('../icons/find.png')} height={100} width={100} alt='2OCR - Quickly find what you’re looking for' />
                    </div>
                    <div className="App-info-subtitle">
                        <h2>Quickly find what you’re looking for</h2>
                    </div>
                    <div className="App-info-text">
                        2ocr tool provides you with 2 files: original and recognized. Recognized file is a searchable PDF with words at
                        the same position as it was in original file and even each page in the document will be with the same layout.
                        <br />If
                        you need to copy some value from a document - you don’t even need to download the recognized document, just copy
                        this value directly from your browser
                    </div>
                </Grid>
            </Grid>
            <Grid container item xs={12} spacing={8}>
                <Grid item md={4} xs={12} className="App-info-container">
                    <div className="App-info-icon">
                        <img src={require('../icons/lang.png')} height={100} width={100} alt='2OCR - Support multiple languages' />
                    </div>
                    <div className="App-info-subtitle">
                        <h2>Support multiple languages</h2>
                    </div>
                    <div className="App-info-text">
                        Arabic OCR, Armenian OCR, Belorussian OCR, Bengali OCR, Bulgarian OCR, Catalan OCR, Chinese OCR, Croatian OCR,
                        Czech OCR, Danish OCR, Dutch OCR, English OCR, Estonian OCR, Finnish OCR, French OCR, German OCR, Greek OCR,
                        Hungarian OCR, Italian OCR, Japanese OCR, Korean OCR, Latvian OCR, Lithuanian OCR, Macedonian OCR, Malay OCR,
                        Norwegian OCR, Polish OCR, Portuguese OCR, Romanian OCR, Russian OCR, Serbian OCR, Slovak OCR, Slovenian OCR,
                        Spanish OCR, Swedish OCR, Thai OCR, Turkish OCR, Vietnamese OCR
                    </div>
                </Grid>
                <Grid item md={4} xs={12} className="App-info-container">
                    <div className="App-info-icon">
                        <img src={require('../icons/free.png')} height={100} width={180} alt='2OCR - Free. Online. Anonymous' />
                    </div>
                    <div className="App-info-subtitle">
                        <h2>Free. Online. Anonymous</h2>
                    </div>
                    <div className="App-info-text">
                        Recognition is always free and anonymous. No email or any other personal information required
                    </div>
                </Grid>
                <Grid item md={4} xs={12} className="App-info-container">
                    <div className="App-info-icon">
                        <img src={require('../icons/secure.png')} height={100} width={100} alt='2OCR - Secure and Easy' />
                    </div>
                    <div className="App-info-subtitle">
                        <h2>Secure and Easy</h2>
                    </div>
                    <div className="App-info-text">
                        2ocr tool uses HTTPS protocol for file transferring and files automatically deleted within a few hours after
                        recognition so you don’t need to worry about security
                    </div>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Info;