import * as React from 'react';
import App from './App';
import Terms from './components/Terms';

const SimpleRouter = () => {
    if (window.location.pathname === '/terms-and-privacy') {
        return <Terms />;
    }

    return (<App />);
};

export default SimpleRouter;