import React from 'react';

const Terms = () => {
    return (
        <div className="App-terms">
            <div className="App-content-title">
                <h1>Terms and Privacy</h1>
            </div>
            <div className="App-terms-content">
                <p>
                    Thank you for using 2ocr.<br /> These terms of service (the “Terms”) govern your access to and use of 2ocr (“we”) website, please
                    carefully
                    read them before using the Website. Unless we agree with you in writing that your individual terms and conditions are somehow
                    different from the ones outlined in this document, you are bound by these Terms when using the Website.
                </p>
                <p>
                    By using our Website you provide us with information, files that you submit to 2ocr (“users data”). You retain full ownership of
                    your
                    data. We do not claim any ownership to any of it. These Terms do not grant us any rights to your data or intellectual property
                    except
                    for the limited rights that are needed to run the Website, as explained below.
                </p>
                <p>
                    You are solely responsible for your conduct, the content of your files, and your communications with others while using the
                    Website.
                    For example, it’s your responsibility to ensure that you have the rights or permission needed to comply with these Terms.
                </p>
                <p>
                    We may choose to review public content for compliance with our guidelines, but you acknowledge that 2ocr has no obligation to
                    monitor
                    any information on the Website. We are not responsible for the accuracy, completeness, appropriateness, or legality of files, user
                    posts, or any other information you may be able to access using the Website.
                </p>
                <p>
                    2ocr is Provided “AS-IS”
                    THE SERVICES IS PROVIDED “AS IS”, AT YOUR OWN RISK, WITHOUT EXPRESS OR IMPLIED WARRANTY OR CONDITION OF ANY KIND. WE ALSO DISCLAIM
                    ANY
                    WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT. 2ocr will have no responsibility for any harm
                    to
                    your computer system, loss or corruption of data, or other harm that results from your access to or use of the Services or
                    Software.
                    Some states do not allow the types of disclaimers in this paragraph, so they may not apply to you.
                </p>
                <p>
                    2ocr may reasonably collect the documents uploaded (OCR data) for ongoing service and technology improvement
                </p>
                <p>
                    Cookies<br />
                    This website uses cookies.
                    Cookies are text files cached in your browser that help us to understand how the website is used by and be able to provide you
                    with personalized and great user experience.
                    We use third-party advertising companies to show ads when you visit the website.
                    Those companies may collect information about your visits in order to provide advertisements that may be of interest to you.
                    The IP address they collect is not personally identifiable.
                    It can only identify your basic information.
                    Our third party advertisers include Google Adwords which may employ cookies on our site to serve ads.
                    You may wish to visit Google Adwords for additional information about their privacy policies.
                    Any user may opt out of Google's cookies by
                    visiting: <a href='http://www.google.com/privacy_ads.html'>http://www.google.com/privacy_ads.html</a>.
                    Users can block or restrict cookies from the website through their internet browser settings.
                </p>
                <p>
                    Tracking<br />
                    We use Google Analytics tool on our website.
                </p>
                <p>
                    Changes to the privacy policy
                    The Privacy Policy may be changed occasionally and you are advised to check it from time to time.
                </p>
            </div>
        </div>
    );
};

export default Terms;