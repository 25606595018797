import {Typography} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import FileSaver from 'file-saver';
import React, {Component} from 'react';
import CircularDeterminate from './CircularDeterminate';
import PDFViewer from './PdfViewer';
import TabWithClose from './TabWithClose';

function TabContainer({children}) {
    return (
        <div className='App-files-pdf-view'>
            {children}
        </div>
    );
}

class MultipleTabs extends Component {
    constructor(props) {
        super(props);
        this.handleClickPdf = this.handleClickPdf.bind(this);
        this.handleClickText = this.handleClickText.bind(this);
    }

    handleClickPdf() {
        const {documents, activeIndex} = this.props;
        const blob = new Blob([documents[activeIndex].textPdf], {type: 'application/pdf'});
        let filename = documents[activeIndex].name;
        filename = filename.split('.').slice(0, -1).join('.');
        FileSaver.saveAs(blob, `${filename}-ocr.pdf`);
    }

    handleClickText() {
        const {activeIndex, documents} = this.props;
        const blob = new Blob([documents[activeIndex].plainText], {type: 'text/plain;charset=utf-8'});
        let filename = documents[activeIndex].name;
        filename = filename.split('.').slice(0, -1).join('.');
        FileSaver.saveAs(blob, `${filename}-ocr.txt`);
    }

    renderSuggestion = (suggestion) => {
        let pagesTotal = 0;
        if (!suggestion) {
            return null;
        }

        const match = suggestion.match(/pages_limit_reached_(\d+)_of_(\d+)/g);

        if (match && match.length) {
            pagesTotal = match[0].match(/(\d+)/g)[0];
        }

        return (
            <Typography style={{marginBottom: 15}}>
                2ocr shows recognition results for the first {pagesTotal} pages only.<br />
                If you would like to recognize documents with more than {pagesTotal} pages,
                please sign up via the following link: <a
                href='https://app.scandocflow.com/signup'
            >https://app.scandocflow.com</a>
            </Typography>
        )
    };

    render() {
        const {documents, activeIndex, handleChange, removeDocument} = this.props;

        if (!documents || !documents.length) {
            return null;
        }

        return (
            <div className="App-files-root">
                <div className="App-files-tabs-and-header">
                    <div className="header-recognition">Recognition results:</div>
                    <Tabs
                        value={activeIndex}
                        onChange={(e, value) => handleChange(e, value)}
                        indicatorColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                    >
                        {documents.map((el, index) => React.cloneElement(<TabWithClose/>, {
                            label: `${el.name} - ${el.languageId}`,
                            key: index,
                            onIconClick: () => removeDocument(index),
                            iconProps: {},
                            canBeDeleted: !!el.requestPdf,
                        }))}
                    </Tabs>
                </div>
                <TabContainer>
                    {documents[activeIndex].requestPdf && documents[activeIndex].textPdf ?
                        <div>
                            <Grid
                                style={{margin: '10px 0px', textAlign: 'center'}}
                                container
                            >
                                <Grid item xs={12}>
                                    {this.renderSuggestion(documents[activeIndex].suggestion)}
                                </Grid>
                                {/*<Button variant="contained" color="primary" onClick={this.handleClickPdf}>Save PDF</Button>*/}
                                <Grid item xs={12}>
                                    <Button variant="contained" color="primary" onClick={this.handleClickText}>Save recognized Text</Button>
                                </Grid>
                            </Grid>
                            <Grid container spacing={0} id='inner'>
                                <Grid item xs={12} lg={6} className="child" id={`requestPdf-${activeIndex}`}>
                                    <PDFViewer
                                        containerId={`pdf-request-${activeIndex}`}
                                        rawData={documents[activeIndex].requestPdf}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={6} className="child" id={`textPdf-${activeIndex}`}>
                                    <PDFViewer
                                        containerId={`pdf-text-${activeIndex}`}
                                        rawData={documents[activeIndex].textPdf}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                        :
                        <CircularDeterminate/>
                    }
                </TabContainer>
            </div>
        );
    }
}

export default MultipleTabs;
