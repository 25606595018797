import Grid from '@material-ui/core/Grid';
import 'animate.css';
import React from 'react';
import CookieConsent from 'react-cookie-consent';
import * as ReactGA from 'react-ga';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MetaTags from 'react-meta-tags';
import './App.css';
import Dropzone from './components/Dropzone';
import Info from './components/Info';
import MultipleTabs from './components/MultipleTabs';
import {defaultLanguageLocalStorageKey, keywordsTranslations, languagesDict, languageUrlPrefix} from './constants';
import NavBarHorizontal from "./components/NavBar/NavBarHorizontal";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

const cookiesText = 'This site uses cookies to deliver our services and to ensure you get the best experience. ' +
    'By continuing to use this site, you consent to our use of cookies and acknowledge that you have read ' +
    'and understand our ';

class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            documents: [],
            activeTab: 0,
            header: 'Free online OCR tool',
            isOpen: false,
        };

        this.server = {
            dev: 'http://localhost:3000/v1/api/documents/recognize',
            prod: 'https://api.2ocr.com/v1/api/documents/recognize',
        };
    }

    componentDidMount() {
        ReactGA.initialize('UA-148521430-3', {
            gaOptions: {
                cookieFlags: 'max-age=7200;secure;samesite=none',
            },
        });

        const {pathname} = window.location;

        const splittedPathname = pathname.split(languageUrlPrefix);
        const language = Object.entries(languagesDict).find(([key, value]) => {
            if (splittedPathname[1] === 'chinese' && key === 'chi') {
                return true;
            }

            if (splittedPathname[1] === 'auto' && key === 'auto') {
                return true;
            }

            return value.toLowerCase() === splittedPathname[1];
        });

        if (language) {
            const [value, label] = language;

            localStorage.setItem(defaultLanguageLocalStorageKey, value);
            const title = `Online OCR - ${label}`;
            document.title = title;
            this.setState({header: title, languageId: value, label: label});
        }
    }

    showNotification = (title, message) => {
        toast.error(`${title}: ${message}`);
    };

    showInfoNotification = (title, message) => {
        toast.info(`${title}: ${message}`);
    };

    handleDialogOpen = (value) => {
        if (this.state.isOpen !== value) {
            this.setState({isOpen: value});
        }
    };

    callback = (file, languageId) => {
        if (file.xhr && file.xhr.response) {
            const inputJson = JSON.parse(file.xhr.response);

            if (inputJson.answer.textPdf && inputJson.answer.requestPdf && inputJson.answer.plainText && inputJson.answer.languageId) {
                const textPdfDecoded = Buffer.from(inputJson.answer.textPdf, 'base64');
                const requestPdfDecoded = Buffer.from(inputJson.answer.requestPdf, 'base64');
                const plainTextDecoded = Buffer.from(inputJson.answer.plainText, 'base64');

                // Object.keys(inputJson.answer).forEach(function(key) {
                //     console.log(key);
                // });

                const {documents} = this.state;
                let isAdded = false;
                for (let i = 0; i < documents.length; i++) {
                    if (documents[i].name === file.name && (documents[i].languageId === inputJson.answer.languageId || documents[i].languageId === 'auto')) {
                        documents[i] = {
                            name: file.name,
                            languageId: inputJson.answer.languageId,
                            textPdf: textPdfDecoded,
                            requestPdf: requestPdfDecoded,
                            plainText: plainTextDecoded,
                            suggestion: inputJson.answer.suggestion,
                        };
                        isAdded = true;
                    }
                }

                if (!isAdded) {
                    documents.push({
                        name: file.name,
                        languageId: inputJson.answer.languageId,
                        textPdf: textPdfDecoded,
                        requestPdf: requestPdfDecoded,
                        plainText: plainTextDecoded,
                        suggestion: inputJson.answer.suggestion,
                    });
                }

                this.setState({documents});
            } else {
                let errorText = 'Something went wrong, please try again later';
                if (inputJson.answer.error && inputJson.answer.error.message) {
                    errorText = inputJson.answer.error.message;
                }
                const filename = file.name || '';

                this.showNotification(filename, errorText);

                this.removeCallback(file);
            }
        } else {
            const {documents} = this.state;
            documents.push({name: file.name, languageId: languageId, textPdf: null, requestPdf: null});
            this.setState({documents, activeTab: documents.length - 1});
        }
    };

    removeCallback = (file) => {
        if (file.name) {
            let {documents} = this.state;
            const documentIndex = documents.findIndex(document => document.name === file.name);
            documents.splice(documentIndex, 1);
            this.setState({documents});
        }
    };

    handleChange = (e, value) => {
        this.setState({activeTab: value});
    };

    removeDocument = (index) => {
        this.setState((state) => {
            state.documents.splice(index, 1);
            return state;
        });
    };

    render() {
        const {documents, activeTab, header, languageId, label, isOpen} = this.state;

        const server = (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') ? this.server.dev : this.server.prod;

        let keywordsContent = `OCR ${label}, Free OCR ${label}, Free online OCR ${label}`;
        if (keywordsTranslations[label]) {
            keywordsContent += `, ${keywordsTranslations[label]}`
        }

        return (
            <div className='App'>
                {label ?
                    <MetaTags>
                        <meta name="keywords" content={keywordsContent} />
                    </MetaTags>
                    : null
                }
                <div className='App-header'>
                    <Grid container spacing={0} alignItems='center'>
                        <Grid item md={1} xs={4} sm={2} className='App-header-icon'>
                            <img src={require('./icons/logo.png')} alt='2OCR - Free online OCR tool' width={100} height={100} />
                        </Grid>
                        <Grid item md={4} xs={8} sm={6}>
                            <h2>2OCR - Online OCR tool</h2>
                            <h3>Convert image to a searchable text</h3>
                            <h3><a href="mailto:info@2ocr.com">info@2ocr.com</a></h3>
                        </Grid>
                    </Grid>
                </div>
                <hr color='#e0e0e0' />
                <NavBarHorizontal languageId={languageId} />
                <div className='App-content'>
                    <ToastContainer />
                    <div className='App-content-title'>
                        <h1>
                            {header}
                        </h1>
                        <h2>
                            This OCR tool is free to use and do not require any registration or email address, <b>powered by the <a href='https://scandocflow.com/ocr-api'>ScanDocFlow OCR API</a></b>
                        </h2>
                    </div>
                    <div className='App-dropzone'>
                        <Dropzone
                            callback={this.callback}
                            removeCallback={this.removeCallback}
                            showNotification={this.showNotification}
                            postUrl={server}
                            showInfoNotification={this.showInfoNotification}
                            handleDialogOpen={this.handleDialogOpen}
                        />
                    </div>
                    <MultipleTabs
                        documents={documents}
                        handleChange={this.handleChange}
                        activeIndex={Math.min(activeTab, Math.max(documents.length - 1, 0))}
                        removeDocument={this.removeDocument}
                    />
                    <Info />
                    <CookieConsent
                        buttonClasses='cookies-button'
                        buttonWrapperClasses='cookies-button-wrapper'
                        containerClasses='cookies-toolbar'
                        buttonText='Accept'
                    >
                        <p className='cookies-text'>
                            {cookiesText}
                            <a
                                href='/terms-and-privacy'
                                style={{color: 'rgb(255, 212, 45)'}}
                            >
                                Privacy Policy, Cookie Policy and Terms
                            </a>
                        </p>
                    </CookieConsent>
                    <Dialog
                        open={isOpen}
                        keepMounted
                        onClose={this.handleClose}
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <DialogTitle>{'Daily limit has reached'}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-slide-description">
                                To continue using the OCR, please sign up for free at ScanDocFlow application
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => this.handleDialogOpen(false)}>Cancel</Button>
                            <Button color='primary' variant='contained' onClick={() => {
                                this.handleDialogOpen(false);
                                window.open('https://app.scandocflow.com/signup', "_blank"); //to open new page
                            }}>Go to APP</Button>
                        </DialogActions>
                    </Dialog>
                </div>
                <footer className='App-footer'>
                    <a href='/terms-and-privacy'>Terms and Privacy</a>
                    <div>© 2ocr.com 2020-2022</div>
                </footer>
            </div>
        );
    }
}

export default App;
