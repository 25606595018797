import {MenuItem, Paper, TextField, Typography} from '@material-ui/core';
import {emphasize} from '@material-ui/core/styles/colorManipulator';
import React from 'react';

const NoOptionsMessage = (props) => (
    <Typography
        color="textSecondary"
        className={props.selectProps.classes.noOptionsMessage}
        {...props.innerProps}
    >
        {props.children}
    </Typography>
);

const inputComponent = ({inputRef, ...props}) => (
    <div ref={inputRef} {...props} />
);

const Control = ({selectProps, innerRef, children, isFocused, hasValue, innerProps}) => (
    <TextField
        fullWidth
        label={selectProps.label}
        InputProps={{
            inputComponent,
            inputProps: {
                className: selectProps.classes.input,
                inputRef: innerRef,
                children,
                ...innerProps,
            },
        }}
        InputLabelProps={{
            shrink: hasValue || isFocused,
        }}
        {...selectProps.textFieldProps}
    />
);

const Option = (props) => (
    <MenuItem
        buttonRef={props.innerRef}
        selected={props.isFocused}
        component="div"
        style={{
            fontWeight: props.isSelected ? 500 : 400,
        }}
        {...props.innerProps}
    >
        {props.children}
    </MenuItem>
);

const Placeholder = (props) => (
    <Typography
        color="textSecondary"
        className={props.selectProps.classes.placeholder}
        {...props.innerProps}
    >
        {props.children}
    </Typography>
);

const SingleValue = (props) => (
    <Typography
        className={props.selectProps.classes.singleValue}
        {...props.innerProps}
    >
        {props.children}
    </Typography>
);

const ValueContainer = (props) => (
    <div
        className={props.selectProps.classes.valueContainer}
    >
        {props.children}
    </div>
);

const Menu = (props) => (
    <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
        {props.children}
    </Paper>
);

export const components = {
    Option,
    Control,
    NoOptionsMessage,
    Placeholder,
    SingleValue,
    ValueContainer,
    Menu,
};

export const selectStyles = theme => ({
    root: {
        flexGrow: 1,
        paddingTop: '2px',
        paddingBottom: '4px',
    },
    input: {
        display: 'flex',
    },
    valueContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
    },
    chip: {
        margin: `${theme.spacing(1) / 2}px ${theme.spacing(1) / 4}px`,
    },
    chipFocused: {
        backgroundColor: emphasize(
            theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
            0.08,
        ),
    },
    noOptionsMessage: {
        padding: `${theme.spacing(1)}px ${theme.spacing(1) * 2}px`,
    },
    singleValue: {
        fontSize: 16,
    },
    placeholder: {
        position: 'absolute',
        left: 2,
        fontSize: 16,
    },
    paper: {
        marginTop: theme.spacing(1),
        zIndex: 2000,
    },
    helperText: {
        marginTop: 0,
        minHeight: '0.7em',
    },
    defaultFormControlStyle: {
        marginTop: 10,
        minWidth: 260,
    },
    errorText: {
        color: '#f10a0a',
    },
});
