import {withStyles} from '@material-ui/core/styles';
import React from 'react';
import Select from 'react-select';
import {languagesDict} from '../../constants';
import {components, selectStyles} from './reactSelectConstants';

const defaultSelectProps = {
  menuPosition: 'fixed',
  menuPlacement: 'auto',
  isClearable: true,
  isSearchable: true,
  simpleValue: true,
  backspaceRemoves: true,
};

const AutocompleteInput = ({theme, classes, value, changeValue}) => {
  const selectStyles = {
    input: base => ({
      ...base,
      color: theme.palette.text.primary,
    }),
    clearIndicator: base => ({
      ...base,
      color: '#000000',
    }),
    dropdownIndicator: base => ({
      ...base,
      color: '#000000',
    }),
    indicatorSeparator: base => ({
      ...base,
      background: '#000000',
    }),

  };

  return (
      <Select
          label="Language"
          onChange={(choice) => changeValue(choice && choice.value)}
          components={components}
          options={Object.entries(languagesDict).map(([key, value]) => ({value: key, label: value}))}
          value={{value: value, label: languagesDict[value]}}
          placeholder={null}
          styles={selectStyles}
          classes={classes}
          {...defaultSelectProps}
      />
  );
};

export default withStyles(selectStyles, {withTheme: true})(AutocompleteInput);