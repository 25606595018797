export const languagesDict = {
  auto: 'Auto detect',
  ara: 'Arabic',
  hye: 'Armenian',
  bel: 'Belorussian',
  ben: 'Bengali',
  bul: 'Bulgarian',
  cat: 'Catalan',
  chi: 'Chinese Simplified',
  hrv: 'Croatian',
  ces: 'Czech',
  dan: 'Danish',
  nld: 'Dutch',
  eng: 'English',
  est: 'Estonian',
  fin: 'Finnish',
  fra: 'French',
  deu: 'German',
  ell: 'Greek',
  hun: 'Hungarian',
  ita: 'Italian',
  jpn: 'Japanese',
  kor: 'Korean',
  lav: 'Latvian',
  lit: 'Lithuanian',
  mkd: 'Macedonian',
  msa: 'Malay',
  nor: 'Norwegian',
  pol: 'Polish',
  por: 'Portuguese',
  ron: 'Romanian',
  rus: 'Russian',
  srp: 'Serbian',
  slk: 'Slovak',
  slv: 'Slovenian',
  spa: 'Spanish',
  swe: 'Swedish',
  tha: 'Thai',
  tur: 'Turkish',
  ukr: 'Ukrainian',
  vie: 'Vietnamese'
};

export const defaultLanguageLocalStorageKey = 'defaultLanguage';
export const languageUrlPrefix = '/online-ocr-';

export const keywordsTranslations = {
  'Auto detect': 'Any language recognition, online language recognition, auto detect language recognition, OCR with language auto detection',
  // Arabic language recognition, Arabic language online recognition, free Arabic language recognition, Arabic OCR
  'Arabic': 'التعرف على اللغة العربية ، التعرف على اللغة العربية عبر الإنترنت ، التعرف المجاني على اللغة العربية ، التعرف الضوئي على الحروف العربية',
  // Armenian language recognition, Armenian language online recognition, free Armenian language recognition, Armenian OCR
  'Armenian': 'Հայոց լեզվի ճանաչում, առցանց հայերեն ճանաչում, անվճար հայերեն ճանաչում, հայերեն OCR',
  // Belarusian language recognition, Belarusian language online recognition, free Belarusian language recognition, Belarusian OCR
  'Belorussian': 'Распазнаванне беларускай мовы, Інтэрнэт-распазнаванне беларускай мовы, Бясплатнае распазнаванне беларускай мовы, Беларускае OCR ',
  // Bengali language recognition, Bengali language online recognition, free Bengali language recognition, Bengali OCR
  'Bengali': 'বাংলা ভাষার স্বীকৃতি, বাংলা ভাষা অনলাইন স্বীকৃতি, বিনামূল্যে বাংলা ভাষার স্বীকৃতি, বাংলা ওসিআর',
  // Bulgarian language recognition, Bulgarian language online recognition, free Bulgarian language recognition, Bulgarian OCR
  'Bulgarian': 'Разпознаване на български език, онлайн разпознаване на български език, безплатно разпознаване на български език, български OCR',
  // Catalan language recognition, Catalan language online recognition, free Catalan language recognition, Catalan OCR
  'Catalan': 'Reconeixement de la llengua catalana, reconeixement de la llengua catalana en línia, reconeixement gratuït de la llengua catalana, OCR català',
  // Chinese language recognition, Chinese language online recognition, free Chinese language recognition, Chinese OCR
  'Chinese Simplified': '中文识别，中文在线识别，免费中文识别，中文OCR',
  // Croatian language recognition, Croatian language online recognition, free Croatian language recognition, Croatian OCR
  'Croatian': 'Prepoznavanje hrvatskog jezika, internetsko prepoznavanje hrvatskog jezika, besplatno prepoznavanje hrvatskog jezika, hrvatski OCR',
  // Czech language recognition, Czech language online recognition, free Czech language recognition, Czech OCR
  'Czech': 'Rozpoznávání českého jazyka, online rozpoznávání českého jazyka, bezplatné rozpoznávání českého jazyka, české OCR',
  // Danish language recognition, Danish language online recognition, free Danish language recognition, Danish OCR
  'Danish': 'Dansk sprog anerkendelse, dansk sprog online anerkendelse, gratis dansk sprog anerkendelse, dansk OCR',
  // Dutch language recognition, Dutch language online recognition, free Dutch language recognition, Dutch OCR
  'Dutch': 'Nederlandse taalherkenning, Nederlandse taal online herkenning, gratis Nederlandse taalherkenning, Nederlandse OCR',
  'English': 'English language recognition, online English language recognition, free English language recognition, English OCR',
  // Estonian language recognition, Estonian language online recognition, free Estonian language recognition, Estonian OCR
  'Estonian': 'Eesti keele äratundmine, eesti keele veebituvastus, tasuta eesti keele äratundmine, eesti OCR',
  // Finnish language recognition, Finnish language online recognition, free Finnish language recognition, Finnish OCR
  'Finnish': 'Suomen kielen tunnustaminen, suomen kielen online-tunnustaminen, ilmainen suomen kielen tunnustaminen, suomalainen OCR',
  // French language recognition, French language online recognition, free French language recognition, French OCR
  'French': 'Reconnaissance de la langue française, reconnaissance de la langue française en ligne, reconnaissance gratuite de la langue française, OCR français',
  // German language recognition, German language online recognition, free German language recognition, German OCR
  'German': 'Deutsch Spracherkennung, Deutsch Sprach Online Erkennung, kostenlose Deutsch Spracherkennung, Deutsch OCR',
  // Greek language recognition, Greek language online recognition, free Greek language recognition, Greek OCR
  'Greek': 'Αναγνώριση ελληνικής γλώσσας, διαδικτυακή αναγνώριση ελληνικής γλώσσας, δωρεάν αναγνώριση ελληνικής γλώσσας, ελληνικό OCR',
  // Hungarian language recognition, Hungarian language online recognition, free Hungarian language recognition, Hungarian OCR
  'Hungarian': 'Magyar nyelvfelismerés, magyar nyelv online felismerés, ingyenes magyar nyelvfelismerés, magyar OCR',
  // Italian language recognition, Italian language online recognition, free Italian language recognition, Italian OCR
  'Italian': 'Riconoscimento della lingua italiana, riconoscimento online della lingua italiana, riconoscimento della lingua italiana gratuito, OCR italiano',
  // Japanese language recognition, Japanese language online recognition, free Japanese language recognition, Japanese OCR
  'Japanese': '日本語認識、日本語オンライン認識、無料日本語認識、日本語OCR',
  // Korean language recognition, Korean language online recognition, free Korean language recognition, Korean OCR
  'Korean': '한국어 인식, 한국어 온라인 인식, 무료 한국어 인식, 한국어 OCR',
  // Latvian language recognition, Latvian language online recognition, free Latvian language recognition, Latvian OCR
  'Latvian': 'Latviešu valodas atpazīšana, latviešu valodas atzīšana tiešsaistē, bezmaksas latviešu valodas atpazīšana, latviešu OCR',
  // Lithuanian language recognition, Lithuanian language online recognition, free Lithuanian language recognition, Lithuanian OCR
  'Lithuanian': 'Lietuvių kalbos pripažinimas, Lietuvių kalbos pripažinimas internete, Nemokamas lietuvių kalbos pripažinimas, Lietuvių OCR',
  // Macedonian language recognition, Macedonian language online recognition, free Macedonian language recognition, Macedonian OCR
  'Macedonian': 'Препознавање македонски јазик, признавање на македонски јазик преку Интернет, бесплатно препознавање македонски јазик, македонски OCR',
  // Malay language recognition, Malay language online recognition, free Malay language recognition, Malay OCR
  'Malay': 'Pengiktirafan bahasa Melayu, pengiktirafan bahasa Melayu dalam talian, pengiktirafan bahasa Melayu percuma, OCR Melayu',
  // Norwegian language recognition, Norwegian language online recognition, free Norwegian language recognition, Norwegian OCR
  'Norwegian': 'Norsk språkgjenkjenning, norsk språk online anerkjennelse, gratis norsk språkgjenkjenning, norsk OCR',
  // Polish language recognition, Polish language online recognition, free Polish language recognition, Polish OCR
  'Polish': 'Rozpoznawanie języka polskiego, rozpoznawanie języka polskiego online, bezpłatne rozpoznawanie języka polskiego, polski OCR',
  // Portuguese language recognition, Portuguese language online recognition, free Portuguese language recognition, Portuguese OCR
  'Portuguese': 'Reconhecimento da língua portuguesa, reconhecimento online da língua portuguesa, reconhecimento gratuito da língua portuguesa, OCR português',
  // Romanian language recognition, Romanian language online recognition, free Romanian language recognition, Romanian OCR
  'Romanian': 'Recunoașterea limbii române, recunoașterea online a limbii române, recunoaștere gratuită a limbii române, OCR română',
  'Russian': 'Распознавание русского языка, Распознавание русский онлайн, бесплатно распознавание русский язык, OCR русский',
  // Serbian language recognition, Serbian language online recognition, free Serbian language recognition, Serbian OCR
  'Serbian': 'Препознавање српског језика, интернетско препознавање српског језика, бесплатно препознавање српског језика, српски ОЦР',
  // Slovak language recognition, Slovak language online recognition, free Slovak language recognition, Slovak OCR
  'Slovak': 'Rozpoznávanie slovenského jazyka, online rozpoznávanie slovenského jazyka, bezplatné rozpoznávanie slovenského jazyka, slovenské OCR',
  // Slovenian language recognition, Slovenian language online recognition, free Slovenian language recognition, Slovenian OCR
  'Slovenian': 'Prepoznavanje slovenskega jezika, spletno prepoznavanje slovenskega jezika, brezplačno prepoznavanje slovenskega jezika, slovenski OCR',
  // Spanish language recognition, Spanish language online recognition, free Spanish language recognition, Spanish OCR
  'Spanish': 'Reconocimiento del idioma español, reconocimiento en línea del idioma español, reconocimiento gratuito del idioma español, OCR en español',
  // Swedish language recognition, Swedish language online recognition, free Swedish language recognition, Swedish OCR
  'Swedish': 'Svenskt språkigenkänning, Svenskspråkigt online-erkännande, gratis svenskt erkännande, Svensk OCR',
  // Thai language recognition, Thai language online recognition, free Thai language recognition, Thai OCR
  'Thai': 'การรู้จำภาษาไทย, การจดจำออนไลน์ภาษาไทย, การจดจำภาษาไทยฟรี, ไทยโอซีอาร์',
  // Turkish language recognition, Turkish language online recognition, free Turkish language recognition, Turkish OCR
  'Turkish': 'Türkçe dil tanıma, Türkçe çevrimiçi tanıma, ücretsiz Türkçe dil tanıma, Türkçe OCR',
  'Ukrainian': 'Розпізнавання українського тексту, Розпізнавання української онлайн, безкоштовне розпізнавання української, OCR українська',
  // Vietnamese language recognition, Vietnamese language online recognition, free Vietnamese language recognition, Vietnamese OCR
  'Vietnamese': 'Nhận dạng ngôn ngữ tiếng Việt, nhận dạng tiếng Việt trực tuyến, nhận dạng tiếng Việt miễn phí, OCR tiếng Việt'
}
