import 'animate.css';
import React, {Component} from 'react';
import DropzoneComponent from 'react-dropzone-component';
import 'react-dropzone-component/styles/filepicker.css';
import '../_extensions.scss';
import '../App.css';
import * as ReactGA from 'react-ga';
import LanguagesDialog from './LanguagesDialog';
import ReCAPTCHA from "react-google-recaptcha";

class Dropzone extends Component {
    recaptchaRef = React.createRef();

    constructor(props) {
        super(props);
        const {postUrl} = props;

        this._isManualDeletion = true;

        const onlyHostSpl = postUrl.split('/');
        const onlyHost = `${onlyHostSpl[0]}/${onlyHostSpl[1]}/${onlyHostSpl[2]}`;

        this.djsConfig = {
            addRemoveLinks: true,
            // acceptedFiles: 'image/webp,image/jpeg,image/png,image/gif,image/tiff,image/bmp,application/pdf,text/plain,application/rtf,application/x-rtf,text/rtf,text/richtext,application/doc,application/x-soffice,image/x-ms-bmp,application/msword,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            headers: {'Access-Control-Allow-Origin': onlyHost},
            timeout: 600000,
            autoProcessQueue: false,
            dictDefaultMessage: "Drag and Drop or click to select files to OCR"
        };

        this.componentConfig = {
            showFiletypeIcon: false,
            postUrl: postUrl,
            timeout: 600000
        };

        this.dropzone = null;

        this.state = {
            filesLanguages: {},
            // ocrProvider: OCR_PROVIDER_TESSERACT,
            dialogIsOpen: false,
            processingFiles: [],
            captchaToken: ''
        };

        this.sending = this.sending.bind(this);
    }

    success = (file) => {
        const {callback} = this.props;
        this._isManualDeletion = false;
        this.dropzone.removeFile(file);
        callback(file, null);
    };

    closeDialogRemoveFile = (file) => {
        const {processingFiles} = this.state;
        const fileIndex = processingFiles.findIndex(fileIter => fileIter.name === file.name);
        if (fileIndex !== -1) {
            processingFiles.splice(fileIndex, 1);
            this.setState({processingFiles});
        }

        this._isManualDeletion = true;
        this.dropzone.removeFile(file);
    }

    error = (file) => {
        const {handleDialogOpen} = this.props;

        if (!file.accepted) { // in case if file type not supported
            this.closeDialogRemoveFile(file);
            const {showNotification} = this.props;
            showNotification(file.name, 'Sorry, this file type currently not supported');
        } else if (file.status === 'error') { // receiving this event even manually delete file
            this.closeDialogRemoveFile(file);
            const {showNotification} = this.props;
            if (file.xhr && file.xhr.status === 0) {
                showNotification(file.name, 'Too many requests, rate limit exceeded. Please, try again later');
            } else {
                let errorText = 'Something went wrong, please try again later';
                try {
                    const answer = JSON.parse(file.xhr.response);
                    if (answer.error && answer.error.message) {
                        errorText = answer.error.message;
                    }
                } catch (e) {
                }

                if (errorText === 'Limit reached') {
                    handleDialogOpen(true);
                }

                showNotification(file.name, errorText);
            }
        }
    };

    handleDialogClose = (filename) => {
        const {processingFiles} = this.state;
        const {showInfoNotification} = this.props;

        const fileIndex = processingFiles.findIndex(file => file.name === filename);
        if (fileIndex !== -1) { // TODO: handle -1
            const file = processingFiles[fileIndex];
            this._isManualDeletion = true;
            this.dropzone.removeFile(file);

            processingFiles.splice(fileIndex, 1);

            this.setState({processingFiles});
            showInfoNotification('Info', `File: "${filename}" was removed`);
        }
    };

    added = file => {
        if (!file.hasOwnProperty('accepted') || file.accepted === true) {
            const {processingFiles} = this.state;
            processingFiles.push(file);
            this.setState({processingFiles});
        }
    };

    removeCallback = file => {
        if (this._isManualDeletion) {
            const {removeCallback} = this.props;
            removeCallback(file);
        } else {
            this._isManualDeletion = true;
        }
    };

    sending = async (file, xhr, data) => {
        const {filesLanguages, captchaToken} = this.state;
        const languageId = filesLanguages[file.name] || 'eng'; // 'eng' is default lang
        data.append('languageId', languageId);
        data.append('captchaToken', captchaToken);

        ReactGA.event({
            category: 'File',
            action: 'File upload',
            label: languageId,
            value: 1,
            transport: 'beacon'
        });
    };

    handleChange = event => {
        this.setState({[event.target.name]: event.target.value});
    };

    handleLanguageButtonClick = async (languageId, filename) => {
        const {processingFiles: files, filesLanguages} = this.state;

        if (files && files.length) {
            const fileIndex = files.findIndex(file => file.name === filename);
            const file = files[fileIndex];
            files.splice(fileIndex, 1);

            filesLanguages[filename] = languageId;

            const captchaToken = await this.recaptchaRef.current.executeAsync();
            this.recaptchaRef.current.reset();

            this.setState({captchaToken, filesLanguages, processingFiles: files}, () => {
                this.dropzone.processFile(file);

                const {callback} = this.props;
                callback(file, languageId);
            })
        }
    };

    render() {
        const config = this.componentConfig;
        const djsConfig = this.djsConfig;

        // For a list of all possible events (there are many), see README.md!
        const eventHandlers = {
            init: dz => this.dropzone = dz,
            success: this.success,
            error: this.error,
            addedfile: this.added,
            sending: this.sending,
            removedfile: this.removeCallback,
        };

        const {processingFiles} = this.state;

        return (
            <div className="dropzone-wrapper">
                <div className="row">
                    <DropzoneComponent
                        className='filePicker'
                        config={config}
                        eventHandlers={eventHandlers}
                        djsConfig={djsConfig}
                    />
                    {
                        !!processingFiles.length ?
                            <LanguagesDialog
                                handleClose={this.handleDialogClose}
                                handleClick={this.handleLanguageButtonClick}
                                processingFiles={processingFiles}
                            />
                            : null
                    }
                    <ReCAPTCHA
                        ref={this.recaptchaRef}
                        sitekey={'6LfjRswgAAAAAO8qCjzUH4Z4Q6-GsBell_rRAH7p'}
                        size='invisible'
                    />
                </div>
            </div>
        )
    }
}

export default Dropzone;
